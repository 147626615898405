<template>
    <CModal
      :title="title"
      :color="color"
      :show.sync="serialModal"
    >   
        <CForm>
            <CRow>
              <CCol md="6">
                <CSelect
                  label="Certificado: "
                  :options="certificateOptions"
                  :value.sync="$v.form.certificate_id.$model"
                  :isValid="checkIfValid('certificate_id')"
                  placeholder=""
                  invalidFeedback="Este campo es requerido."
                />
              </CCol>
              <CCol md="6">
                <CSelect
                    label="Tipo de Documento: "
                    :options="typesOptions"
                    :value.sync="$v.form.type.$model"
                    :isValid="checkIfValid('type')"
                    placeholder=""
                    invalidFeedback="Este campo es requerido."
                  />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CInput
                  label="Serie:"
                  :lazy="false"
                  :value.sync="$v.form.serial.$model"
                  :isValid="checkIfValid('serial')"
                  placeholder=""
                  autocomplete="code"
                  invalidFeedback="Este campo es requerido."
              />
              </CCol>
              <CCol md="6">
                <CInput
                  label="Folio Actual:"
                  :lazy="false"
                  :value.sync="$v.form.current.$model"
                  :isValid="checkIfValid('current')"
                  placeholder=""
                  autocomplete="name"
                  invalidFeedback="Este campo es requerido."
              />
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, integer, email} from "vuelidate/lib/validators"
import certificates_ws from '../../../services/certificates';

export default { 
  name: 'SerieModal',
  props: {

  },
  
  data () {
    return {
      serialModal: false,
      title: "Nueva Serie", 
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      certificates: [],
      certificateOptions: [],
      typesOptions: [{label: 'Ingreso', value: 'I'}, {label: 'Egreso', value: 'E'}, {label: 'Pago', value: 'P'/* , {label: 'Nómina', value: 'N'} */}]
    }
  },
  mounted: async function() {
    let response = await certificates_ws.get(); 

    if(response.type == "success"){
      this.certificates = response.data;
      this.certificateOptions = await this.$parseSelectOptionsOrdered(this.certificates, "id", "certificate_number");

      this.form.certificate_id = this.certificates[0].id;
    }
  },
  methods: {
    storeModal () {
      this.serialModal = true;
      this.color = "info";
      this.title = 'Nueva Serie';
      this.isEdit = false;
      this.submitted = false;

      this.form = this.getEmptyForm();
      this.form.certificate_id = this.certificates[0].id;
    },
    updateModal (item) {
      this.serialModal = true;
      this.color = "warning";
      this.title = 'Editar Serie';
      this.isEdit = true;
      this.submitted = false;

      this.form = {
          id: item.id,
          certificate_id: item.certificate_id,
          type: item.type == "I" || item.type == "income" ? "I" : (item.type == "E" || item.type == "expense" ? "E" : (item.type == "payment" || item.type == "P" ? "P" : "N")),
          serial: item.serial,
          current: item.current
      };
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        if(this.form.id > 0){
          this.$emit("update", this.form );
        }
        else{
          this.$emit("store", this.form );
        }

        this.closeModal();
      }
    },
    closeModal () {
      this.serialModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          certificate_id: '',
          type: 'I',
          serial: 'F',
          current: 1
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid)
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      certificate_id: {
        required
      },
      type: {
        required
      },
      serial: {
        required
      },
      current: {
        required
      }
    }
  },
}
</script>