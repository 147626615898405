<template>
  <div>
    <CRow>
      <CCol sm="12">
        <SerialsTable
          :items="serials"
          hover
          striped
          border
          small
          fixed
          caption="Series y Folios"
          @refresh="refreshItems"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import SerialsTable from '../../components/configuration/serials/SerialsTable.vue'
import ws from '../../services/serials';
import store from '../../store'

export default {
  name: 'Serials',
  components: { SerialsTable },
  data: function () {
		return {
            serials: []
        }
  },
  mounted: async function() {
    this.loading();
    let response = await ws.get(); 

    if(response.type == "success"){
      this.serials = response.data;
    }
    this.loaded();
  },
  methods: {
    async refreshItems (items){
      this.loading();
      let response = await ws.get();

      if(response.type == "success"){
        this.serials = response.data;
      }
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
